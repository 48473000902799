import { computed, inject, SecurityContext } from '@angular/core';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { setLoaded, setLoading, withLoading } from '@state/shared/signal-store-features/with-loading/with-loading';
import { AdminApiService } from '@data-access';
import { pipe, switchMap, tap } from 'rxjs';
import { initialAdminStoreState } from './admin-store.constants';
import { isBefore } from 'date-fns';
import { getHandleError } from '../../../smart-ui/utils/with-error-handler';
import { DomSanitizer } from '@angular/platform-browser';

export const AdminStoreService = signalStore(
  { providedIn: 'root', protectedState: false },
  withState(initialAdminStoreState),
  withLoading({ collection: 'bannerDetails' }),
  withComputed((store) => {
    const sanitizer = inject(DomSanitizer);

    return {
      bannerText: computed(() => {
        const bannerDetails = store.bannerDetails();

        if (
          store.bannerDetailsIsLoading() ||
          !store.bannerDetailsIsLoaded() ||
          !bannerDetails ||
          isBefore(bannerDetails.validTo, new Date())
        ) {
          return '';
        }

        return sanitizer.sanitize(SecurityContext.HTML, bannerDetails.msg) ?? '';
      })
    };
  }),
  withMethods((store) => {
    const adminApiService = inject(AdminApiService);
    const handleError = getHandleError();

    return {
      readBannerDetails: rxMethod<void>(
        pipe(
          switchMap(() => {
            patchState(store, setLoading(true, 'bannerDetails'), setLoaded(false, 'bannerDetails'));

            return adminApiService.readBanner().pipe(
              tap((bannerDetails) => {
                patchState(
                  store,
                  { bannerDetails },
                  setLoading(false, 'bannerDetails'),
                  setLoaded(true, 'bannerDetails')
                );
              }),
              handleError(() => {
                patchState(store, setLoading(false, 'bannerDetails'), setLoaded(false, 'bannerDetails'));
              })
            );
          })
        )
      )
    };
  })
);
